export default {
  primary: '#DA2A2D',
  primaryDark: '#851a1b',
  background: '#fbe9ea',
  warning: '#da822b',
  info: '#2baeda',
  secondary: '#f56e88',
  secondaryDark: '#a04859',
  text: '#3F3D56',
};
