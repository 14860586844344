export default ([
  {
    id: '1',
    title: 'Selection',
    description: 'Select the Kanji character you want to practice. You can also see the kanji\'s details by pressing it longer.',
    image: require('../../svg/HomeScreen').default,
  },
  {
    id: '2',
    title: 'Configuration',
    description: 'You can setup the application at will to define the number of flashcard, the time limit, etc. But also save your current progression and load it on an other device',
    image: require('../../svg/Editable').default,
  },
  {
    id: '3',
    title: 'Run',
    description: 'Once all Kanji characters and the application setted, you can run a set of quizz.',
    image: require('../../svg/ProjectComplete').default,
  },
]);
